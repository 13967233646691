import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const WhatWeDoPage = props => (
  <Layout>
    <SEO title="What We Do" />

    <BackgroundImage
      fluid={props.data.whoAreWeImage.childImageSharp.fluid}
      alt=""
    >
      <Header headerText="What We Do"></Header>
    </BackgroundImage>

    <Section>
      <p>
        <strong>Wealth Planning</strong> - helping you achieve the things that
        are most important to you without jeopardising your financial security.
        We will help you establish your goals and plan with you how you can
        achieve this by striking the right balance between what you spend today
        and can save for tomorrow. A goal without a plan is just a wish.
      </p>
      <p>
        <strong>Safety Planning</strong> - making sure your loved ones,
        beneficiaries or your business are financially protected in the event of
        your death or serious illness.
      </p>
      <p>
        <strong>Tax Planning</strong> - making use of allowances, exemptions and
        tax-planning opportunities to minimise your taxes. Working in
        conjunction with your professional tax adviser<sup>*</sup>.
      </p>
      <p>
        <strong>Investment Planning</strong> - ensuring we understand the risk
        you need to take, want to take and can tolerate to achieve your goals
        and ensure you have a portfolio that matches it. We will ensure
        appropriate asset allocation, safe custody and reliable administration,
        while minimising costs and taxes.
      </p>

      <p style={{ textAlign: "center" }}>
        <strong>You talk, we listen, then act accordingly.</strong>
      </p>
      <p>
        <small>
          * Tax planning is not regulated by the Financial conduct authority
        </small>
      </p>

      <TwmButton toRef="/contact-us">Contact Us</TwmButton>
    </Section>
  </Layout>
)

export default WhatWeDoPage

export const pageQuery = graphql`
  query {
    whoAreWeImage: file(relativePath: { eq: "what-we-do.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
